section.empty-simple {
  @apply text-center;
  > svg {
    @apply mx-auto h-12 w-12 text-gray-400;
  }
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    @apply mt-2 text-base font-medium text-gray-900;
  }

  > p {
    @apply mt-1 text-sm text-gray-500 max-w-xl mx-auto;
  }
  > button {
    @apply mt-6 inline-flex items-center text-sm font-medium rounded-md btn btn-primary;
    > svg {
      @apply -ml-1 mr-2 h-5 w-5;
    }
  }
}
