@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Additional styles */
@import './styles/additional-styles/utility-patterns.css';
@import './styles/additional-styles/range-slider.css';
@import './styles/additional-styles/toggle-switch.css';
@import './styles/additional-styles/theme.css';

@import 'tailwindcss/utilities';

@import './styles/button.css';
@import './styles/boxed.css';
@import './styles/dropdown.css';
@import './styles/empty.css';
@import './styles/footer.css';
@import './styles/form.css';
@import './styles/header.css';
@import './styles/loader.css';
@import './styles/main.css';
@import './styles/pagination.css';
@import './styles/table.css';

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
  .rtl {
    direction: rtl;
  }
}
@layer base {
  :root {
    --colorPrimary: hsl(var(--p));
    --colorSecondary: hsl(var(--s));
  }
  .main {
    @apply px-4 py-16 sm:px-6 sm:py-24 lg:px-8;
    @apply boxed;
    @apply min-h-full;
    @apply bg-white;
    @apply text-left;
  }
  body {
    @apply bg-white text-gray-900 dark:bg-gray-900 dark:text-gray-100 tracking-tight;
  }
}

* {
  scroll-behavior: smooth;
}

/* #__next {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#__next > main {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
} */
