.boxed {
  @apply max-w-7xl mx-auto;
}

.boxed--sm {
  @apply max-w-3xl mx-auto;
}

.boxed--xs {
  @apply max-w-xl mx-auto;
}
