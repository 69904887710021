nav.pagination {
  @apply flex items-center justify-between gap-2 flex-wrap;
  @apply p-2 md:p-0;
  > * {
    @apply flex items-center gap-2;
  }

  button {
    @apply btn btn-xs;
    @apply pb-1; /* because < and << are not evenly distributed */
  }
  input[type='number'] {
    @apply input input-bordered input-sm;
    @apply w-20;
  }
}
