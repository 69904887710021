fieldset {
  @apply form-control px-2 sm:px-4 md:px-0 text-left;

  label {
    @apply label text-left;

    > span:first-child {
      @apply label-text;
    }
    > span:nth-child(2) {
      @apply label-text-alt;
    }
  }

  legend {
    @apply text-xl leading-6 font-bold text-gray-900 border-b border-gray-300 block text-left w-full pb-3;

    + * {
      @apply mt-5;
    }
  }
}

input[type='checkbox'] {
  @apply checkbox;
}

textarea {
  @apply textarea textarea-bordered;
  border-radius: 3px;
}
select {
  border-radius: 3px !important;
}

input:not([type]),
input[type='text'],
input[type='url'],
input[type='email'],
input[type='tel'],
input[type='number'],
input[type='password'] {
  @apply input input-bordered input-sm;
  border-radius: 3px;
}

input[readOnly],
input[readonly] {
  @apply input-ghost text-opacity-50 bg-gray-200;
}

input[type='button'],
button[type='submit'],
input[type='submit'] {
  @apply btn;
}

input + label,
input + span + label {
  /* after an input field */
  @apply mt-3;
}

input + span {
  /* help for field */
  @apply px-1 text-left italic text-gray-800 text-sm;
}

fieldset + fieldset {
  @apply mt-5;
}

fieldset + button,
fieldset + input {
  @apply mt-5 mx-2 sm:mx-4 md:mx-0;
}

select {
  @apply select select-bordered select-sm;
}

.form-error {
  @apply text-red-700 text-sm text-left block;
}
