table.simple {
  @apply table table-sm table-pin-cols table-pin-rows;
  @apply w-full overflow-x-auto; /* To be able to scroll horizontally */

  .filters {
    @apply flex gap-2 mt-2;
    input[type='text'] {
      @apply w-36 input-xs border shadow-md px-2 py-0;
    }
    input[type='number'] {
      @apply w-16 input-xs border shadow-md px-2 py-0;
    }
  }
}
