footer.simple {
  @apply bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-100;
  @apply py-12 md:py-16 border-t border-gray-200 dark:border-gray-800 -mt-px;

  > section {
    @apply max-w-7xl mx-auto px-4 sm:px-0;
    @apply flex flex-col md:flex-row justify-between items-start gap-2;

    > ul {
      @apply flex items-center justify-between gap-5;
    }
    & + section {
      @apply text-xs mt-5;
      @apply items-center;
      @apply text-gray-600 dark:text-gray-400;
    }
  }

  ul.horizontal {
    @apply flex items-center gap-5;
    li:not(:first-child) {
      list-style-type: disc;
    }
  }

  figure {
    @apply flex gap-2 items-center;
    @apply font-extrabold;
  }
}
