#nprogress .bar {
  height: 4px;
  background: var(--colorPrimary);
  background: linear-gradient(90deg, var(--colorPrimary) 0%, var(--colorSecondary) 100%);
}
#nprogress .spinner-icon {
  border-color: white;
  border-top-color: var(--colorPrimary);
  border-bottom-color: var(--colorSecondary);
  animation: nprogress-spinner 800ms linear infinite;
}

#nprogress .peg {
  box-shadow: none;
}

#nprogress .spinner {
  top: 5px;
  right: 5px;
}
