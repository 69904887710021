header.simple {
  @apply sticky w-full z-30 top-0;
  @apply overflow-y-visible;
  @apply bg-gray-100 text-gray-900 dark:bg-gray-900 dark:text-gray-100;
  @apply flex items-center justify-center h-20 gap-5;

  > nav {
    @apply w-full max-w-7xl mx-auto px-4 sm:px-6;
    @apply flex items-center justify-between;
    > a {
      @apply flex items-center;
      @apply font-bold;

      &:nth-child(1) {
        @apply shrink-0 items-center;
      }
    }
  }

  figure {
    @apply flex gap-2 items-center;
    @apply font-extrabold;
  }
}
