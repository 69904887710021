/* Typography */
.h1 {
  @apply text-4xl font-black tracking-tighter;
}

.h2 {
  @apply text-3xl font-black tracking-tighter;
}

.h3 {
  @apply text-3xl font-black;
}

.h4 {
  @apply text-2xl font-black tracking-tight;
}

@screen md {
  .h1 {
    @apply text-5xl;
  }

  .h2 {
    @apply text-4xl;
  }
}

/* Buttons */
.btn-appy,
.btn-appy-sm {
  @apply font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out;
}

.btn-appy {
  @apply px-8 py-3;
}

.btn-appy-sm {
  @apply px-4 py-2;
}

/* Forms */
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply bg-white border border-gray-300 focus:border-gray-400;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply leading-snug py-3 px-4;
}

.form-input,
.form-textarea {
  @apply placeholder-gray-500;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-teal-600;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.dark .h4 {
  @apply font-bold;
}

.dark .form-input,
.dark .form-textarea,
.dark .form-multiselect,
.dark .form-select,
.dark .form-checkbox,
.dark .form-radio {
  @apply bg-gray-800 border border-gray-600 focus:border-gray-500;
}

.dark .form-input,
.dark .form-textarea {
  @apply placeholder-gray-400;
}
