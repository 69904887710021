main.aside-layout {
  max-width: 100%;
  overflow: hidden;

  > header {
    @apply flex items-center justify-between;
    button {
      @apply btn btn-xs btn-primary;
    }
  }

  h1 {
    @apply py-3 sm:py-5;
    @apply px-2 md:px-0;
    @apply text-lg font-bold;
  }
}
