.loader {
  @apply loading loading-spinner;
}
.loader-xs {
  @apply loader loading-xs;
}
.loader-sm {
  @apply loader loading-sm;
}
.loader-md {
  @apply loader loading-md;
}
.loader-lg {
  @apply loader loading-lg;
}
